/*global jQuery, window */
(function($) {

  'use strict';

  var attivaModal = function(message, buttons, idButtons, callBack) {
      $('.modal-body p').hide();
      $('.modal-body p').html(message);
      $('.modal-body p').show();

      if (!$('.modal').hasClass('in')) {
        $('.modal').modal('show');
      }

      $('.modal-footer').html('');

      if (typeof buttons !== 'undefined' && buttons !== null) {
        if (buttons.length > 0) {
          $.each(buttons, function(index, value) {
            if (idButtons[index] === '') {
              $('.modal-footer').append('<button type="button" class="btn btn-default" data-dismiss="modal">' + value + '</button>');
            } else {
              $('.modal-footer').append('<button type="button" class="btn btn-default" id="modal-action-' + idButtons[index] + '">' + value + '</button>');
            }
          });
        }
      }
      if (typeof callBack === 'function') {
        callBack();
      }
    };

    /* validation form contatti */

    $('body').on('click', '.invia-contatto', function() {
      var validator = $('#form-contatti').validate({
        focusInvalid: false,
      onkeyup: false,
      onfocusout: false,
      errorElement: 'p',
      errorPlacement: function(error) {
        var tmpl = '<span class="glyphicon glyphicon-remove-circle" style="font-size: 70px; color:orange;"></span><br><br>' +
          '<h4>' + $(error).html() + '</h4><br>';

        attivaModal(tmpl, ['Chiudi'], ['']);

        $('div#errors').show();
        if ($('div#errors').html() === '') {
          error.appendTo('div#errors');
        }
      },
        rules: {
          nominativo: {
            required: true
          },
          captcha: {
            required: true
          },
          email: {
            required: true,
            email: true
          },
          privacy: {
            required: true
          }
        },
        messages: {
          nominativo: {
            required: 'inserire il nominativo'
          },
          privacy: {
            required: 'E\' necessario dare il consenso sulla privacy'
          },
          captcha: {
            required: 'Inserire il codice di verifica'
          },
          email: {
            required: 'inserire la mail',
            email: 'inserire una mail corretta',
          }
        },
        invalidHandler: function() {
          $('div#errors').html('');
        }
      });

      var arr = {};
      arr.email = $('#email').val();
      arr.nominativo = $('#nominativo').val();
      arr.azienda = $('#azienda').val();
      arr.telefono = $('#telefono').val();
      arr.pagina_id = $('#pagina_id').val();
      arr.pagina = $('#pagina').val();
      arr.documentazione = $('#documentazione').is(':checked');
      arr.messaggio = $('#messaggio').val();
      arr.captcha = $('#captcha').val();
      var jsonString = JSON.stringify(arr);

      if (validator.form()) {
        var tmpl = '<span class="glyphicon glyphicon-time" style="font-size: 70px; color:orange;"></span><br><br>' +
        '<h4>Stiamo elaborando la sua richiesta, attendere un attimo...</h4><br><br>';
        attivaModal(tmpl, null, null);

        $.ajax({
          url: '/ajax/function.php',
          method: 'POST',
          data: {
            'action': 'sendmail',
            'obj': jsonString
          },
          success: function(ret) {
            var arr = ret.split('|');
            var tmpl = '';

            if (arr[0] === '1') {
              tmpl = '<span class="glyphicon glyphicon-send" style="font-size: 70px; color:orange;"></span><br><br><h4>' + arr[1] + '</h4><br><br>';
              $('#email').val('');
              $('#nominativo').val('');
              $('#telefono').val('');
              $('#messaggio').val('');
              $('#azienda').val('');
              $('#captcha').val('');
            } else {
              tmpl = '<span class="glyphicon glyphicon-error" style="font-size: 70px; color:orange;"></span><br><br><h4>' + arr[1] + '</h4><br><br>';
            } 
            attivaModal(tmpl, ['Chiudi'], [''], null);
          }
        });
      }
      return false;
    });

}(jQuery));
